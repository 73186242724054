<template>
  <b-modal id="upload-files" modal-class="common-modal" hide-footer no-close-on-backdrop no-close-on-esc>
    <template slot="modal-header">
      <h5 class="modal-title">{{ $t('general.addFiles') }}</h5>
      <button v-if="!sendingForm" @click="hideModal()" class="close"></button>
    </template>

    <template slot="default">
      <ValidationObserver ref="form">
        <b-form novalidate class="common-form" autocomplete="off" @submit.prevent="validateBeforeSubmit()">
          <!-- Fields -->
          <fields :form="form" @filesAdded="onFilesAdded"></fields>

          <div class="form-actions center">
            <slot>
              <div>
                <b-button type="submit" variant="primary" :disabled="sendingForm" block size="big">{{ sendButtonText }}</b-button>
              </div>
              <div>
                <b-button variant="danger" :disabled="sendingForm" block size="big" @click="hideModal">{{ $t('general.cancel') }}</b-button>
              </div>
            </slot>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
@import './Upload';
</style>

<script>
import bus from '@/api/bus';
import commonSrv from '@/api/common';
import filesSrv from '@/api/files';
import { showError, showSuccess } from '@/components/ScreenAlert';
import i18n from '@/i18n';
import { ValidationObserver } from 'vee-validate';

export default {
  components: {
    ValidationObserver,
    Fields: () => import('./Fields.vue'),
  },
  props: {
    getFiles: Function,
    fileInfo: Object,
  },
  data() {
    return {
      form: {
        project_id: null,
        category_id: null,
        tags: [],
        files: [],
      },
      loadingInfo: null,
      sendingForm: false,
      sendButtonText: i18n.t('general.sendReview'),
      publisehdText: i18n.t('general.toPost'),
      rejectText: i18n.t('general.reject'),
      user: this.$store?.getters?.['auth/user'],
    };
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.from = {};
      const tags = [];
      this.form = commonSrv.cloneObject(this.fileInfo);
      if (this.form.tags) {
        this.form.tags.forEach((element) => {
          tags.push(element.description);
        });
        this.form.tags = tags;
      }
    },
    onFilesAdded(selectedFiles) {
      this.form.files = selectedFiles;
    },

    hideModal() {
      this.$emit('closeModal');
      this.resetForm();
    },

    resetForm() {
      this.sendingForm = false;
      this.sendButtonText = i18n.t('general.sendReview');
      this.form = {
        project_id: null,
        category_id: null,
        name: null,
        description: null,
        tags: [],
        files: [],
      };
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },

    validateBeforeSubmit() {
      return commonSrv.validateForm(this.$refs.form, this.handleSubmit);
    },

    handleSubmit() {
      this.sendButtonText = i18n.t('general.sending');
      this.sendingForm = true;
      const payload = new FormData();
      payload.append('project_id', this.form.project_id);
      payload.append('category_id', this.form.category_id);
      payload.append('name', this.form.name);
      payload.append('description', this.form.description);
      this.form.tags.forEach((tag) => {
        payload.append('tags', tag);
      });
      payload.append('preview_file', this.form.preview_file);
      this.form.files.forEach((file) => {
        payload.append('files', file);
      });

      filesSrv
        .createFiles(payload)
        .then(() => {
          showSuccess({
            confirmLabel: '',
            title: i18n.t('general.information'),
            content: i18n.t('general.successfulOperation'),
          });
          bus.$emit('getFiles');
          this.hideModal();
          this.beforeDestroy();
        })
        .catch(({ message, error }) => {
          this.hideModal();
          showError({
            confirmLabel: '',
            title: this.$t('general.information'),
            content: message || error || this.$t('general.errorMessage'),
          });
        })
        .finally(() => {
          this.sendingForm = false;
        });
    },
    beforeDestroy() {
      if (this.formType === 'edition') {
        this.getFiles();
      }
    },
  },
};
</script>
